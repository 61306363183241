import {memo, useCallback, useMemo} from 'react';

import {AppBar, SOMLogo, UserProfile, useUserContext} from '@reasoncorp/kyber-js';

import {ssoApi} from '../api';
import config from '../config';

const ProServAppBar = () => {
  const {currentUser, permissions} = useUserContext();
  const handleProfileClick = useCallback(() => window.location.href = `${config.sso.webUrl}/user-profile`, []);

  const handleSignOut = useCallback(async () => {
    await ssoApi.signOut();
  }, []);

  const renderUserProfile = useMemo(() => {
    return currentUser ? () => <UserProfile handleProfileClick={handleProfileClick}
                                            handleSignOut={handleSignOut}/> : undefined;
  }, [
    currentUser,
    handleSignOut,
    handleProfileClick
  ]);

  const routes = useMemo(() => {
    if (permissions.hasFinancialServicesAccess) {
      return [
        {name: 'Bona Fides', to: '/bona-fides'}
      ];
    } else {
      return [
        {name: 'Denials', to: '/denials'},
        {name: 'Appeals', to: '/appeals'},
        {name: 'Bona Fides', to: '/bona-fides'},
        {name: 'Interest Waivers', to: '/interest-waivers'},
        {name: 'Qualified Errors', to: '/qualified-errors'},
        {name: 'Reports', to: '/reports', hide: !permissions.hasImportAndLetterAccess}
      ];
    }
  }, [
    permissions
  ]);

  const appBarProps = useMemo(() => ({
    brandLink: `${config.sso.webUrl}/dashboard`,
    brandImage: SOMLogo,
    brandImageAlt: 'ProSuite',
    appName: 'ProServ',
    organizationName: 'The Department of Treasury',
    environmentName: config.envName !== 'prod' ? config.envName : undefined,
    routes,
    renderUserProfile
  }), [
    renderUserProfile,
    routes
  ]);

  return (
    <AppBar {...appBarProps}/>
  );
};

export default memo(ProServAppBar);
