import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {
  CallsCard,
  DocumentsCard,
  LettersCard,
  NotesCard,
  ParcelCard,
  RecordNavBar,
  RelatedParcelRecordsCard
} from '../../components/shared';
import {BonaFideOwnerInformationCard, BonaFidePaymentCard, BonaFideTaxDueDetailsCard} from '../../components/bonaFides';
import {bonaFideApi, bonaFideLetterApi, callApi, noteApi} from '../../api';
import {Call, DocumentResponse, Note} from '../../types';
import {BonaFideDto} from '../../types/bonaFide';
import {ParcelRequest} from '../../types/request';
import {BonaFideRequest} from '../../types/request/bonaFide';
import * as messages from '../../messages';
import BonaFidePaymentRequest from '../../types/request/bonaFide/BonaFidePaymentRequest';

const BonaFide = () => {
  const id = Number(useParams<{id: string}>().id);
  const {permissions} = useUserContext();
  const [bonaFide, setBonaFide] = useState<BonaFideDto | undefined>(undefined);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);
  const [letters, setLetters] = useState<DocumentResponse[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [calls, setCalls] = useState<Call[]>([]);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});

  const loadBonaFide = useCallback(async () => {
    try {
      const bonaFide = await bonaFideApi.find(id);
      setBonaFide(bonaFide);

      if (!permissions.hasFinancialServicesAccess) {
        const [documents, letters] = await Promise.all([
          bonaFideApi.findDocuments(id),
          bonaFideApi.findLetters(id)
        ]);

        setDocuments(documents);
        setLetters(letters);

        const [notes, calls] = await Promise.all([
          noteApi.findAll(bonaFide.parcel.id),
          callApi.findAll(bonaFide.parcel.id)
        ]);
        setNotes(notes);
        setCalls(calls);
      }
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
    }
  }, [
    id,
    showErrorAlert,
    permissions
  ]);

  useEffect(() => {
    void loadBonaFide();
  }, [
    loadBonaFide
  ]);

  const handleSave = useCallback(async (bonaFideRequest: BonaFideRequest,
                                        isDeletingYear: boolean = false,
                                        isCancelingYear: boolean = false) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      const bonaFide = await bonaFideApi.save(id, bonaFideRequest);

      if (isCancelingYear && bonaFide.letterBatchId) {
        await bonaFideLetterApi.recreateBatch(bonaFide.letterBatchId);
      }

      setBonaFide(bonaFide);
      showSuccessAlert(
        isDeletingYear ?
          messages.BONA_FIDE_TAX_DETAILS_DELETE_SUCCESSFUL :
          messages.BONA_FIDE_SAVE_SUCCESSFUL
      );

      if (isCancelingYear && bonaFide.letterBatchId !== null) {
        setTimeout(async () => {
          const letters = await bonaFideApi.findLetters(bonaFide.id);
          setLetters(letters);
        }, 1000 * 20);
      }
    } catch (e) {
      showErrorAlert(
        isDeletingYear ?
          messages.BONA_FIDE_TAX_DETAILS_DELETE_FAILURE :
          messages.BONA_FIDE_SAVE_FAILURE
      );
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  }, [
    showErrorAlert,
    showSuccessAlert,
    id
  ]);

  const handleParcelSave = useCallback((parcel: ParcelRequest) => handleSave({
    ...bonaFide,
    parcel
  } as BonaFideRequest), [
    bonaFide,
    handleSave
  ]);

  const handlePaymentSave = useCallback(async (bonaFidePaymentRequest: BonaFidePaymentRequest) => {
    setLoadingState({loading: false, loadError: false, processing: true});

    try {
      const bonaFide = await bonaFideApi.createPayment(id, bonaFidePaymentRequest);
      setBonaFide(bonaFide);
      showSuccessAlert(messages.BONA_FIDE_PAYMENT_LOG_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.BONA_FIDE_PAYMENT_LOG_FAILURE);
    } finally {
      setLoadingState({loading: false, loadError: false, processing: false});
    }
  }, [
    id,
    showErrorAlert,
    showSuccessAlert
  ]);

  const renderButtons = useMemo(() => () => <>
    <Button color="primary"
            disabled={loadingState.processing}
            onClick={() => window.print()}
            className="mr-2">
      Print
    </Button>
  </>, [
    loadingState.processing
  ]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && bonaFide && <>
      <Row className="mb-4">
        <Col className="align-self-center" sm="3">
          <h1 className="h5 mb-0">Bona Fide: {bonaFide.fileNumber}</h1>
        </Col>
        <Col className="d-flex justify-content-end" sm="9">
          <RecordNavBar parcel={bonaFide.parcel}
                        bonaFideId={bonaFide.id}
                        denialId={bonaFide.denialId}
                        interestWaiverId={bonaFide.interestWaiverId}
                        createButtonsDisabled={bonaFide.year < new Date().getFullYear() - 5}
                        onRecordSave={loadBonaFide}
                        currentRecordType="BONA_FIDE"
                        renderButtons={renderButtons}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ParcelCard parcel={bonaFide.parcel}
                      onSave={handleParcelSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <BonaFideOwnerInformationCard bonaFide={bonaFide}
                                        onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <BonaFideTaxDueDetailsCard bonaFide={bonaFide}
                                     onSave={handleSave}/>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <BonaFidePaymentCard bonaFide={bonaFide}
                               onSave={handlePaymentSave}/>
        </Col>
      </Row>
      {!permissions.hasFinancialServicesAccess && <Row className="mb-4">
        <Col className="col-6">
          <RelatedParcelRecordsCard parcelId={bonaFide.parcel.id}/>
        </Col>
      </Row>}
      {!permissions.hasFinancialServicesAccess && <>
        <NotesCard notes={notes}
                   parcelId={bonaFide.parcel.id}
                   onSaveSuccess={loadBonaFide}/>
        <CallsCard calls={calls}
                   parcelId={bonaFide.parcel.id}
                   onSaveSuccess={loadBonaFide}/>
        <DocumentsCard className="mb-4"
                       documents={documents}
                       parcelId={bonaFide.parcel.id}
                       onSuccess={loadBonaFide}/>
        <LettersCard letters={letters}/>
      </>}
    </>}
  </Container>;
};

export default BonaFide;