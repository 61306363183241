import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext, UsState} from '@reasoncorp/kyber-js';

import {BonaFideDto} from '../../types/bonaFide';
import {BonaFideRequest} from '../../types/request/bonaFide';
import {bonaFideOwnerSchema} from '../../schema/bonaFide';
import {OwnerRequest, SimpleAddressRequest} from '../../types/request';

type Props = {
  bonaFide: BonaFideDto
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

type FormFields = {
  owner: OwnerRequest
  mailingAddress: SimpleAddressRequest
}

const BonaFideOwnerInformationCard = ({
                                        bonaFide,
                                        onSave
                                      }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (formFields: FormFields) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      ...formFields
    } as BonaFideRequest;

    await onSave(bonaFideRequest);
  }, [
    bonaFide,
    onSave
  ]);

  const initialValues: FormFields = useMemo(() => ({
    owner: {
      ownerFirstName: bonaFide.owner.ownerFirstName,
      ownerMiddleName: bonaFide.owner.ownerMiddleName,
      ownerLastName: bonaFide.owner.ownerLastName,
      coOwnerFirstName: bonaFide.owner.coOwnerFirstName,
      coOwnerMiddleName: bonaFide.owner.coOwnerMiddleName,
      coOwnerLastName: bonaFide.owner.coOwnerLastName,
      ownerCareOf: bonaFide.owner.ownerCareOf,
      ownerCompany: bonaFide.owner.ownerCompany
    },
    mailingAddress: {
      address: bonaFide.mailingAddress.address,
      city: bonaFide.mailingAddress.city,
      state: bonaFide.mailingAddress.state,
      zip: bonaFide.mailingAddress.zip
    }
  }), [
    bonaFide
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Owner and Mailing Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Owner</th>
            <th>Co-Owner</th>
            <th>Additional Owner(s)</th>
            <th>Care Of</th>
            <th>Mailing Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bonaFide.owner.ownerName}&nbsp;</td>
            <td>{bonaFide.owner.coOwnerName}&nbsp;</td>
            <td>{bonaFide.owner.ownerCompany}&nbsp;</td>
            <td>{bonaFide.owner.ownerCareOf}&nbsp;</td>
            <td>{bonaFide.mailingAddress.fullDisplay}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Owner and Mailing Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideOwnerSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="owner.ownerFirstName"
                             labelText="Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerMiddleName"
                             labelText="Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerLastName"
                             labelText="Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="owner.coOwnerFirstName"
                             labelText="Co-Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerMiddleName"
                             labelText="Co-Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerLastName"
                             labelText="Co-Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikInput name="owner.ownerCompany"
                             labelText="Additional Owner(s)"/>
              </Col>
              <Col sm="6">
                <FormikInput name="owner.ownerCareOf"
                             labelText="Care Of"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="mailingAddress.address"
                             labelText="Mailing Street"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="mailingAddress.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="mailingAddress.state"
                              labelText="State">
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state =>
                    <option value={state} key={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="mailingAddress.zip"
                             labelText="Zip"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success" className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideOwnerInformationCard;