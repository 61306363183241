import {formatBoolean, formatDate, formatDateTime, formatDecimal, formatMoney, formatPercent} from './formattingUtils';
import {isNumber, zeroIfNull} from './mathUtils';
import * as bonaFideCalculationUtils from './bonaFideCalculationUtils';
import * as bonaFidePaymentUtils from './bonaFidePaymentUtils';

const getAvailableDenialYears = Array.from({length: 7})
  .map((_, i) => new Date().getFullYear() - i)
  .filter(year => year >= 2015);

const getAvailableBonaFideDenialYears = Array.from({length: 8})
  .map((_, i) => new Date().getFullYear() - i)
  .filter(year => year >= 2014);

export {
  getAvailableDenialYears,
  getAvailableBonaFideDenialYears,
  bonaFideCalculationUtils,
  bonaFidePaymentUtils,
  formatBoolean,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatMoney,
  formatPercent,
  isNumber,
  zeroIfNull
};