import {DocumentResponse} from '../types';
import {BonaFideDto, BonaFideSearchResponse} from '../types/bonaFide';
import {BonaFidePaymentRequest, BonaFideRequest, BonaFideSearchRequest} from '../types/request/bonaFide';
import {proServApi} from './apiUtils';

export const find = (id: number): Promise<BonaFideDto> => proServApi.getWithJsonResponse(
  `/bona-fides/${id}`
);

export const create = (bonaFideRequest: BonaFideRequest): Promise<BonaFideDto> =>
  proServApi.postWithJsonResponse(
    `/bona-fides`,
    {
      body: JSON.stringify(bonaFideRequest)
    }
  );

export const search = (bonaFideSearchRequest: BonaFideSearchRequest): Promise<BonaFideSearchResponse[]> =>
  proServApi.postWithJsonResponse(
    '/bona-fides/search',
    {
      body: JSON.stringify(bonaFideSearchRequest)
    }
  );

export const save = (id: number, bonaFideRequest: BonaFideRequest): Promise<BonaFideDto> =>
  proServApi.putWithJsonResponse(
    `/bona-fides/${id}`,
    {
      body: JSON.stringify(bonaFideRequest)
    }
  );

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/bona-fides/${id}/documents`
);

export const findLetters = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/bona-fides/${id}/letters`
);

export const createPayment = (id: number,
                              bonaFidePaymentRequest: BonaFidePaymentRequest): Promise<BonaFideDto> => proServApi.postWithJsonResponse(
  `/bona-fides/${id}/payments`,
  {
    body: JSON.stringify(bonaFidePaymentRequest)
  }
)