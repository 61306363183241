import {BonaFideDenialYear, BonaFideDto} from '../types/bonaFide';

export const findBonaFideDenialYearById = (bonaFide: BonaFideDto,
                                           id: number | string) => {
  return bonaFide.denialYears
    .find((dy: BonaFideDenialYear) => id !== '' && dy.id === Number(id));
};

export const findBonaFidePaymentsByDenialYear = (bonaFide: BonaFideDto,
                                                 dy: BonaFideDenialYear) => {
  return bonaFide.payments
    .filter(payment => payment.year === dy.year && payment.taxLevySeason === dy.taxLevySeason);
};

export const findBonaFidePaymentForAllYears = (bonaFide: BonaFideDto) => {
  return bonaFide.payments
    .find(payment => payment.paymentForAllYears);
}