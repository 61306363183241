import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {bonaFideCalculationUtils, bonaFidePaymentUtils, formatDate, formatMoney} from '../../util';
import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {
  CustomTable,
  FormikDateInput,
  FormikDecimalInput,
  FormikInput,
  FormikSelect,
  useUserContext
} from '@reasoncorp/kyber-js';

import {BonaFideDenialYear, BonaFideDto, BonaFidePayment} from '../../types/bonaFide';
import {BonaFidePaymentRequest} from '../../types/request/bonaFide';
import {bonaFidePaymentSchema} from '../../schema/bonaFide';

type Props = {
  bonaFide: BonaFideDto
  onSave: (bonaFidePaymentRequest: BonaFidePaymentRequest) => Promise<void>
}

const BonaFidePaymentCard = ({
                               bonaFide,
                               onSave
                             }: Props) => {
  const {permissions} = useUserContext();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Year', sortKey: 'year'},
      {title: 'Name', sortKey: 'checkName'},
      {title: 'Check #', sortKey: 'checkNumber'},
      {title: 'Check Date', sortKey: 'checkPaymentOn', className: 'text-center'},
      {title: 'Deposit #', sortKey: 'depositNumber'},
      {title: 'Deposit Date', sortKey: 'depositedOn', className: 'text-center'},
      {title: 'Amount', sortKey: 'amount', className: 'text-center w-15'}
    ],
    items: bonaFide.payments,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    noResultsMessage: 'No payments found.',
    renderRow: (payment: BonaFidePayment) => {
      const yearDisplayValue = payment.paymentForAllYears ?
        'Total Due' : `${payment.year} - ${payment.taxLevySeasonDisplayValue}`;

      return <tr>
        <td className="font-weight-bold text-primary align-middle">
          {yearDisplayValue}
        </td>
        <td className="align-middle">
          {payment.checkName}
        </td>
        <td className="align-middle">
          {payment.checkNumber}
        </td>
        <td className="text-center align-middle">
          {formatDate(payment.checkPaymentOn)}
        </td>
        <td className="align-middle">
          {payment.depositNumber}
        </td>
        <td className="text-center align-middle">
          {formatDate(payment.depositedOn)}
        </td>
        <td className="text-center align-middle">
          {formatMoney(payment.amount)}
        </td>
      </tr>;
    }
  }), [
    bonaFide.payments
  ]);

  const initialValues: BonaFidePaymentRequest = useMemo(() => ({
    id: null,
    amount: null,
    year: null,
    taxLevySeason: null,
    checkName: '',
    checkNumber: '',
    depositNumber: '',
    checkPaymentOn: '',
    depositedOn: '',
    paymentForAllYears: false,
    paymentYear: ''
  }), []);

  const handlePaymentYearChange = useCallback((e: React.ChangeEvent<HTMLInputElement>,
                                               {
                                                 setFieldValue
                                               }: FormikProps<any>) => {
    const value = e.target.value;
    const paymentForAllYears = value === 'ALL';
    setFieldValue('paymentForAllYears', paymentForAllYears);

    if (paymentForAllYears) {
      setFieldValue('taxLevySeason', null);
      setFieldValue('year', null);
    } else {
      const paymentYear = bonaFidePaymentUtils.findBonaFideDenialYearById(bonaFide, value);

      if (paymentYear) {
        setFieldValue('taxLevySeason', paymentYear.taxLevySeason);
        setFieldValue('year', paymentYear.year);
      }
    }
  }, [
    bonaFide
  ]);

  const handleSave = useCallback(async (bonaFidePaymentRequest: BonaFidePaymentRequest,
                                        helpers: FormikHelpers<BonaFidePaymentRequest>) => {
    setIsPaymentModalOpen(false);

    await onSave(bonaFidePaymentRequest);
    helpers.resetForm();
  }, [
    onSave
  ]);

  const renderPaymentYearOption = useMemo(() => (denialYear: BonaFideDenialYear) => {
    const totalBalanceIsPaid = bonaFide.remainingBalanceAmount === 0 || bonaFide.remainingBalanceAmount < 0;
    const outstandingBalance = !totalBalanceIsPaid ? bonaFideCalculationUtils.calculateOutstandingBalance(bonaFide, denialYear) : 0;
    const hasAllYearsPaymentBeenMade = bonaFidePaymentUtils.findBonaFidePaymentForAllYears(bonaFide) !== undefined;
    const hasPaymentBeenMade = hasAllYearsPaymentBeenMade ||
      bonaFidePaymentUtils.findBonaFidePaymentsByDenialYear(bonaFide, denialYear).length > 0;
    const outstandingBalanceText = hasPaymentBeenMade ? ` - (Outstanding Balance ${formatMoney(outstandingBalance)})` : '';

    return <option value={denialYear.id}>
      {denialYear.year} - {denialYear.taxLevySeasonDisplayValue} - {formatMoney(denialYear.totalDue)}{outstandingBalanceText}
    </option>;
  }, [
    bonaFide
  ]);

  const renderAllPaymentYearOption = useMemo(() => () => {
    const hasPaymentBeenMade = bonaFide.payments.length > 0;
    const outstandingBalanceText = hasPaymentBeenMade ? ` - (Outstanding Balance ${formatMoney(bonaFide.remainingBalanceAmount)})` : '';
    return <option value="ALL">Total Due - {formatMoney(bonaFide.totalDue)}{outstandingBalanceText}</option>;
  }, [
    bonaFide
  ]);

  const bonaFidePaymentYears = useMemo(() => {
    return bonaFide.denialYears
      .filter(dy => 'CANCELED' !== dy.status);
  }, [
    bonaFide
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="col-6 align-self-center">
            Payments
          </Col>
          <Col className="col-6 justify-content-end d-flex">
            {permissions.hasBonaFidePaymentsAccess && <Button color="primary"
                                                              size="sm"
                                                              onClick={() => setIsPaymentModalOpen(true)}>Log New Payment</Button>}
          </Col>
        </Row>
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>

    <Modal isOpen={isPaymentModalOpen}
           size="xl"
           className="BonaFidePaymentModal"
           returnFocusAfterClose={true}
           autoFocus={true}
           toggle={() => setIsPaymentModalOpen(!isPaymentModalOpen)}>
      <Formik initialValues={initialValues}
              validateOnMount
              enableReinitialize
              validationSchema={bonaFidePaymentSchema}
              onSubmit={handleSave}>
        {(formikProps) => (<>
          <ModalHeader toggle={() => setIsPaymentModalOpen(false)}
                       tag="h2"
                       className="h5 mb-0">
            Log New Payment
          </ModalHeader>
          <ModalBody>
            <Row className="border-bottom mb-4">
              <Col sm={8}>
                <FormikSelect name="paymentYear"
                              autoFocus
                              onChange={(e) => {
                                handlePaymentYearChange(e, formikProps);
                              }}
                              aria-required
                              labelText="Select Year to Apply">
                  <option value="">Select</option>
                  {bonaFidePaymentYears.map(renderPaymentYearOption)}
                  {renderAllPaymentYearOption()}
                </FormikSelect>
              </Col>
            </Row>
            <Row className="border-bottom mb-4">
              <Col sm={12}>
                <FormikInput name="checkName"
                             aria-required
                             disableFloatingLabel={true}
                             labelText="Name"/>
              </Col>
            </Row>
            <Row className="mb-4 border-bottom">
              <Col className="col-4">
                <FormikInput name="checkNumber"
                             aria-required
                             maxLength={50}
                             disableFloatingLabel
                             labelText="Check #"/>
              </Col>
              <Col className="col-4">
                <FormikDecimalInput name="amount"
                                    aria-required
                                    maxLength={15}
                                    disableFloatingLabel
                                    integerPlaces={9}
                                    decimalPlaces={2}
                                    allowNegatives={true}
                                    labelText="Amount"/>
              </Col>
              <Col className="col-4">
                <FormikDateInput name="checkPaymentOn"
                                 aria-required
                                 labelText="Check Date"/>
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <FormikInput name="depositNumber"
                             disableFloatingLabel
                             maxLength={50}
                             aria-required
                             labelText="Deposit #"/>
              </Col>
              <Col className="col-4">
                <FormikDateInput name="depositedOn"
                                 disableFloatingLabel
                                 aria-required
                                 labelText="Deposit Date"/>
              </Col>
            </Row>
            <Row className="pt-4 p-1 border-top mb-0 text-secondary">
              <Col>
                <strong>
                  Payment transactions cannot be undone. To make adjustments or to remove payments, you must log a new (+/-) payment
                </strong>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-2"
                    disabled={!formikProps.isValid || formikProps.isSubmitting}
                    onClick={formikProps.submitForm}>
              Log Payment
            </Button>
            <Button color="secondary"
                    onClick={() => setIsPaymentModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFidePaymentCard;