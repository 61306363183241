import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  downloadUtils,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {BonaFideLetterBatchDeleteModal, BonaFideNavBar} from '../../components/bonaFides';
import {BonaFideLetterBatchDto, BonaFideLetterDto} from '../../types/bonaFide';
import {bonaFideLetterApi} from '../../api';
import * as messages from '../../messages';
import {formatDate} from '../../util';
import {LetterBatchStatusBadge} from '../../components/shared';

const BonaFideLetterBatch = () => {
  const id = Number(useParams<{id: string}>().id);
  const [bonaFideLetterBatch, setBonaFideLetterBatch] = useState<BonaFideLetterBatchDto | undefined>(undefined);
  const [selectedBonaFideLetter, setSelectedBonaFideLetter] = useState<BonaFideLetterDto | undefined>(undefined);
  const [deleteLetterBatchModalIsOpen, setDeleteLetterBatchModalIsOpen] = useState(false);
  const [deleteLetterModalIsOpen, setDeleteLetterModalIsOpen] = useState(false);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const navigate = useNavigate();

  const loadBonaFideLetterBatch = useCallback(async () => {
    try {
      setLoadingState({loading: true, loadError: false, processing: false});
      const bonaFideLetterBatch = await bonaFideLetterApi.findBatch(id);
      setBonaFideLetterBatch(bonaFideLetterBatch);
      setLoadingState({loading: false, loadError: false, processing: false});

      // Refresh every 20 seconds
      if (bonaFideLetterBatch.status === 'PROCESSING') {
        setInterval(() => loadBonaFideLetterBatch().then(), 1000 * 20);
      }
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  }, [
    id,
    showErrorAlert
  ]);


  useEffect(() => {
    void loadBonaFideLetterBatch();
  }, [
    loadBonaFideLetterBatch
  ]);

  const handleDeleteLetter = useCallback(async (letterId: number) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await bonaFideLetterApi.deleteLetter(letterId);
      const bonaFideLetterBatch = await bonaFideLetterApi.findBatch(id);
      setBonaFideLetterBatch(bonaFideLetterBatch);

      if (bonaFideLetterBatch.expectedItemCount === 0) {
        navigate('/bona-fides/letters/batches');
      } else {
        await bonaFideLetterApi.recreateBatch(id);
        await loadBonaFideLetterBatch();
      }

      showSuccessAlert(messages.LETTER_DELETE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.LETTER_DELETE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
    setDeleteLetterModalIsOpen(false);
  }, [
    showErrorAlert,
    showSuccessAlert,
    id,
    navigate,
    loadBonaFideLetterBatch
  ]);

  const handleDeleteLetterBatch = useCallback(async (id: number) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await bonaFideLetterApi.deleteBatch(id);
      navigate('/bona-fides/letters/batches');
      showSuccessAlert(messages.LETTER_BATCH_DELETE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_DELETE_FAILURE);
    }

    setLoadingState({...loadingState, processing: false});
  }, [
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    navigate
  ]);

  const handleDownloadLetter = useCallback(async (id: number) => {
    try {
      await downloadUtils.downloadFile(bonaFideLetterApi.getSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.LETTER_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const handleRecreateBatchLetter = useCallback(async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await bonaFideLetterApi.recreateBatch(id);
      await loadBonaFideLetterBatch();
      showSuccessAlert(messages.LETTER_BATCH_RECREATE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_RECREATE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    loadBonaFideLetterBatch,
    id
  ]);

  const handleDeleteClick = useCallback((bonaFideLetter: BonaFideLetterDto) => {
    setSelectedBonaFideLetter(bonaFideLetter);
    setDeleteLetterModalIsOpen(true);
  }, []);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Years', className: 'text-center bg-light'},
      {title: 'Bona Fide ID', className: 'text-center bg-light', sortKey: 'fileNumber'},
      {title: 'Parcel ID', className: 'text-center bg-light', sortKey: 'parcelNumber'},
      {title: 'Updated ', className: 'text-center bg-light', sortKey: 'updatedAt'},
      {title: 'Letter', className: 'text-center bg-light'},
      {title: 'Remove', className: 'text-center bg-light'}
    ],
    initialSort: {
      sortKey: 'fileNumber',
      direction: 'asc' as const
    },
    items: bonaFideLetterBatch?.letters ?? [],
    renderRow: (bonaFideLetter: BonaFideLetterDto) => {
      return <tr key={bonaFideLetter.id}>
        <td className="text-center align-middle">
          {bonaFideLetter.denialYearsDisplayValue}
        </td>
        <td className="text-center align-middle">
          <Link to={`/bona-fides/${bonaFideLetter.bonaFideId}`}>
            {bonaFideLetter.fileNumber}
          </Link>
        </td>
        <td className="text-center align-middle">
          {bonaFideLetter.parcelNumber}
        </td>
        <td className="text-center align-middle">
          {formatDate(bonaFideLetter.updatedAt)}
        </td>
        <td className="text-center  align-middle">
          <ButtonIcon icon="file-pdf"
                      disabled={loadingState.processing || !bonaFideLetter.active}
                      title={`Download BonaFide ${bonaFideLetter.fileNumber} Letter`}
                      ariaLabel={`Download BonaFide ${bonaFideLetter.fileNumber} Letter`}
                      onClick={() => handleDownloadLetter(bonaFideLetter.id)}/>
        </td>
        <td className="text-center text-danger align-middle">
          <ButtonIcon icon="trash"
                      disabled={loadingState.processing || !bonaFideLetter.active}
                      title={`Delete BonaFide ${bonaFideLetter.fileNumber} Letter`}
                      ariaLabel={`Delete BonaFide ${bonaFideLetter.fileNumber} Letter`}
                      onClick={() => handleDeleteClick(bonaFideLetter)}/>
        </td>
      </tr>;
    },
    paginatorConfig: {
      perPage: 100
    }
  }), [
    bonaFideLetterBatch?.letters,
    handleDownloadLetter,
    handleDeleteClick,
    loadingState.processing
  ]);

  const handleDownloadBatchLetter = useCallback(async () => {
    try {
      await downloadUtils.downloadFile(bonaFideLetterApi.getBatchSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert,
    id
  ]);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Voucher Letter Batches',
      active: false,
      route: '/bona-fides/letters/batches',
      icon: 'home' as const
    },
    {text: bonaFideLetterBatch?.fileNumber ?? '', active: true}
  ]), [
    bonaFideLetterBatch
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col sm={6}>
        <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
      </Col>
      <Col className="d-flex justify-content-end" sm={6}>
        <BonaFideNavBar/>
      </Col>
    </Row>
    {loadingState.loading && <ProgressIndicator/>}
    {bonaFideLetterBatch && !loadingState.loadError && !loadingState.loading && <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="6" className="align-self-center">
                  <span className="mr-2">
                    Voucher Letter Batch {bonaFideLetterBatch.fileNumber}
                  </span>
                </Col>
              </Row>
            </CardHeader>
            <>
              <Row className="bg-light p-2 pt-3 pb-3 m-0 border-bottom">
                <Col sm="6" className="align-self-center">
                  <strong className="mr-2">Status:</strong>
                  <LetterBatchStatusBadge status={bonaFideLetterBatch.status}/>
                </Col>
                <Col sm="6" className="d-flex justify-content-end">
                  <Button color="primary"
                          className="mr-2"
                          disabled={bonaFideLetterBatch.status !== 'ACTIVE'}
                          onClick={() => handleDownloadBatchLetter()}>
                    View Batch PDF
                  </Button>
                  <Button color="primary"
                          className="mr-2"
                          disabled={bonaFideLetterBatch.status === 'PROCESSING'}
                          onClick={() => handleRecreateBatchLetter()}>
                    Recreate Batch
                  </Button>
                  <Button color="primary"
                          onClick={() => setDeleteLetterBatchModalIsOpen(true)}>
                    Delete Batch
                  </Button>
                </Col>
              </Row>
            </>
            <CustomTable {...tableProps}/>
          </Card>
        </Col>
      </Row>
      <BonaFideLetterBatchDeleteModal bonaFideLetterBatch={bonaFideLetterBatch}
                                      isOpen={deleteLetterBatchModalIsOpen}
                                      processing={loadingState.processing}
                                      onDelete={handleDeleteLetterBatch}
                                      onCancel={() => setDeleteLetterBatchModalIsOpen(false)}/>

      {selectedBonaFideLetter && <ConfirmationModal isOpen={deleteLetterModalIsOpen}
                                                    title="Delete Letter"
                                                    size="lg"
                                                    confirmButtonText="Yes"
                                                    cancelButtonText="No"
                                                    confirmButtonDisabled={loadingState.processing}
                                                    cancelButtonDisabled={loadingState.processing}
                                                    confirmCallback={() => handleDeleteLetter(selectedBonaFideLetter.id)}
                                                    cancelCallback={() => setDeleteLetterModalIsOpen(false)}>
        Are you sure you wish to delete the letter for Bona Fide <span className="text-danger">{selectedBonaFideLetter.fileNumber}</span>?
      </ConfirmationModal>}
    </>}
  </Container>;
};

export default BonaFideLetterBatch;