import * as Yup from 'yup'

import * as messages from '../../messages'

const bonaFidePaymentSchema = Yup.object().shape({
  checkName: Yup.string()
    .required(messages.REQUIRED),
  checkNumber: Yup.string()
    .required(messages.REQUIRED),
  checkPaymentOn: Yup.date()
    .required(messages.REQUIRED),
  depositNumber: Yup.string()
    .required(messages.REQUIRED),
  depositedOn: Yup.date()
    .required(messages.REQUIRED),
  amount: Yup.number()
    .nullable()
    .required(messages.REQUIRED),
  paymentYear: Yup.string()
    .required(messages.REQUIRED),
})

export default bonaFidePaymentSchema