import {useMemo} from 'react';
import {Button, Card, CardHeader, Col, Row, Table} from 'reactstrap';

import {useUserContext} from '@reasoncorp/kyber-js';

import {BonaFideDenialYear, BonaFideDto} from '../../types/bonaFide';
import {formatDate, formatDecimal, formatMoney} from '../../util';
import * as bonaFideCalculationUtils from '../../util/bonaFideCalculationUtils';

type Props = {
  denialYear: BonaFideDenialYear
  bonaFide: BonaFideDto
  onEdit: (denialYear: BonaFideDenialYear) => void
  onDelete: (denialYear: BonaFideDenialYear) => void
}

const BonaFideDenialYearCard = ({
                                  denialYear,
                                  bonaFide,
                                  onEdit,
                                  onDelete
                                }: Props) => {
  const {permissions} = useUserContext();

  const outstandingBalance = useMemo(() => {
    return bonaFideCalculationUtils.calculateOutstandingBalance(bonaFide, denialYear);
  }, [
    denialYear,
    bonaFide
  ]);

  const outstandingBalanceClassName = useMemo(() => {
    if (outstandingBalance > 0) {
      return 'text-danger';
    } else if (outstandingBalance < 0) {
      return 'text-success';
    } else {
      return '';
    }
  }, [
    outstandingBalance
  ]);

  return <div className="BonaFideDenialYearCard mt-4 ml-4 mr-4 mb-0">
    <Card>
      <CardHeader>
        <Row>
          <Col className="col-6 align-self-center">
            {denialYear.year}
          </Col>
          <Col className="col-6 justify-content-end d-flex">
            {permissions.hasWriteAccess && <>
              <Button color="primary"
                      className="mr-2"
                      size="sm"
                      onClick={() => onEdit(denialYear)}>
                Edit
              </Button>
              <Button color="danger"
                      size="sm"
                      onClick={() => onDelete(denialYear)}>
                Delete
              </Button>
            </>}
          </Col>
        </Row>
      </CardHeader>
      <Table bordered responsive>
        <thead>
          <tr>
            <th className="text-center">Denied By</th>
            <th className="text-center">Tax Levy</th>
            <th className="text-center">Status</th>
            <th className="text-center">Billing Date: Request Received Date</th>
            <th className="text-center">Millage Rate</th>
            <th className="text-center">Taxable Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center align-middle">
              {denialYear.deniedByDisplayValue}
            </td>
            <td className="text-center align-middle">
              {denialYear.taxLevySeasonDisplayValue}
            </td>
            <td className="text-center align-middle">
              {denialYear.statusDisplayValue}
            </td>
            <td className="text-center align-middle">
              {formatDate(denialYear.billedOn)}
            </td>
            <td className="text-center align-middle">
              {formatDecimal(denialYear.millageRate)}
            </td>
            <td className="text-center align-middle">
              {formatMoney(denialYear.taxableValue)}
            </td>
          </tr>
          <tr>
            <th className="text-center">SSE Penalty</th>
            <th className="text-center">Bill Adjustment</th>
            <th className="text-center">Tax Due</th>
            <th className="text-center">Interest Due</th>
            <th className="text-center">Bill Amount</th>
            <th className="text-center">Outstanding Balance</th>
          </tr>
          <tr>
            <td className="text-center align-middle">
              {formatMoney(denialYear.exemptionPenaltyAmount || 0)}
            </td>
            <td className="text-center align-middle">
              {formatMoney(denialYear.billAdjustmentAmount || 0)}
            </td>
            <td className="text-center align-middle">
              {formatMoney(denialYear.taxDue)}
            </td>
            <td className="text-center align-middle">
              {formatMoney(denialYear.interestDue)}
            </td>
            <td className="text-center align-middle">
              {formatMoney(denialYear.totalDue)}
            </td>
            <td className={`text-center align-middle ${outstandingBalanceClassName}`}>
              {formatMoney(outstandingBalance)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  </div>;
};

export default BonaFideDenialYearCard;