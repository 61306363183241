import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Row, Table} from 'reactstrap';

import {Duration, DurationType, useUserContext} from '@reasoncorp/kyber-js';

import {formatMoney} from '../../util';
import {BonaFideDenialYear, BonaFideDto} from '../../types/bonaFide';
import {BonaFideRequest} from '../../types/request/bonaFide';
import BonaFideDenialYearModal from './BonaFideDenialYearModal';
import BonaFideDeleteYearModal from './BonaFideDeleteYearModal';
import BonaFideDenialYearCard from './BonaFideDenialYearCard';

type Props = {
  bonaFide: BonaFideDto
  onSave: (bonaFideRequest: BonaFideRequest,
           isDeletingYear: boolean,
           isCancelingYear: boolean) => void
}

const BonaFideTaxDueDetailsCard = ({
                                     bonaFide,
                                     onSave
                                   }: Props) => {
  const [isDenialYearModalOpen, setIsDenialYearModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {permissions} = useUserContext();
  const [selectedDenialYear, setSelectedDenialYear] = useState<BonaFideDenialYear | undefined>(undefined);

  const handleSave = useCallback((shouldAddAnother: boolean) => async (bonaFideDenialYear: BonaFideDenialYear,
                                                                       isCancelingYear: boolean) => {
    setIsDenialYearModalOpen(false);
    const denialYears = (bonaFideDenialYear.id !== null && bonaFideDenialYear.id !== undefined) ? [
      ...bonaFide.denialYears.filter(dy => dy.id !== bonaFideDenialYear.id),
      bonaFideDenialYear
    ] : [...bonaFide.denialYears, bonaFideDenialYear];

    const bonaFideRequest = {
      ...bonaFide,
      denialYears
    } as BonaFideRequest;

    await onSave(bonaFideRequest, false, isCancelingYear);

    if (shouldAddAnother) {
      setTimeout(() => {
        setSelectedDenialYear(undefined);
        setIsDenialYearModalOpen(true);
      }, Duration.of(1, DurationType.SECONDS));
    }
  }, [
    bonaFide,
    onSave
  ]);

  const handleDelete = useCallback(async (bonaFideDenialYear: BonaFideDenialYear) => {
    if (isDeleteModalOpen) {
      const denialYears = bonaFide.denialYears.filter(dy => dy.id !== bonaFideDenialYear.id);
      const bonaFideRequest = {
        ...bonaFide,
        denialYears
      } as BonaFideRequest;

      await onSave(bonaFideRequest, true, false);
      setSelectedDenialYear(undefined);
      setIsDeleteModalOpen(false);
    } else {
      setSelectedDenialYear(bonaFideDenialYear);
      setIsDeleteModalOpen(true);
    }
  }, [
    bonaFide,
    isDeleteModalOpen,
    onSave
  ]);

  const handleDeleteCancel = useCallback(() => {
    setSelectedDenialYear(undefined);
    setIsDeleteModalOpen(false);
  }, []);

  const handleEdit = useCallback((denialYear: BonaFideDenialYear) => {
    setSelectedDenialYear(denialYear);
    setIsDenialYearModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedDenialYear(undefined);
    setIsDenialYearModalOpen(false);
  }, []);

  const outstandingBalanceClassName = useMemo(() => {
    if (bonaFide.remainingBalanceAmount > 0) {
      return 'text-danger';
    } else if (bonaFide.remainingBalanceAmount < 0) {
      return 'text-success';
    } else {
      return '';
    }
  }, [
    bonaFide
  ]);

  return <>
    <Card className="BonaFideDenialYears">
      <CardHeader>
        <Row>
          <Col className="col-6 align-self-center">
            Tax Due Billing Details
          </Col>
          <Col className="col-6 justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   size="sm"
                                                   onClick={() => setIsDenialYearModalOpen(true)}>
              Add Year
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      {bonaFide.denialYears.map((denialYear: BonaFideDenialYear) =>
        <BonaFideDenialYearCard denialYear={denialYear}
                                bonaFide={bonaFide}
                                onEdit={handleEdit}
                                onDelete={handleDelete}/>)}
      {bonaFide.denialYears.length === 0 && <Row className="p-4">
        <Col className="font-weight-bold">
          No denial years.
        </Col>
      </Row>}
      <div className={bonaFide.denialYears.length > 0 ? "mt-4" : ""}>
        <Table responsive bordered>
          <tbody>
            <tr className="BonaFideDenialYearsTotal">
              <td colSpan={6}/>
              <td colSpan={6} className="text-right">
                <span className="font-weight-bold text-right mr-2">Total Due:</span>
                <span className="mr-4">
                {formatMoney(bonaFide.totalDue)}
              </span>
                <span className="font-weight-bold mr-1">Amount Paid:</span>
                <span className="mr-4">
                {formatMoney(bonaFide.totalPaidAmount)}
              </span>
                <span className="font-weight-bold text-right mr-2">Outstanding Balance:</span>
                <span className={outstandingBalanceClassName}>{
                  formatMoney(bonaFide.remainingBalanceAmount)}
              </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Card>
    <BonaFideDenialYearModal isOpen={isDenialYearModalOpen}
                             onSubmit={handleSave(false)}
                             onSubmitAndAddAnother={handleSave(true)}
                             onCancel={() => handleCancel()}
                             denialYears={bonaFide.denialYears}
                             selectedDenialYear={selectedDenialYear}/>
    <BonaFideDeleteYearModal isOpen={isDeleteModalOpen}
                             onConfirm={handleDelete}
                             onCancel={handleDeleteCancel}
                             selectedDenialYear={selectedDenialYear}/>
  </>;
};

export default BonaFideTaxDueDetailsCard;